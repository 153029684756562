import {flatten, sortBy,orderBy} from "lodash";
import {now, toMilliSecondsOrNull} from "./time";
import {reportError} from "./logger";
import {frozen, ignoreMinOperationConfidenceOps, minOperationConfidence, turnaroundsStartTimestamp,mergeOperations as mergeOperationsFlag} from "./config";
import {camelCaseKeys, convertObjUtcToMillseconds} from "./data";
import {OTHERS_GROUP} from "../constants/strings";
import Detection from "../models/detection";
import Turnaround from "../models/turnaround";
import CameraOutage from "../models/cameraOutage";
import BBox, { BBoxList } from "../models/bbox";
import Video from "../models/video";
import Audio from "../models/audio";

export function parseTurnarounds(turnarounds: any[], standId: string):Turnaround[] {
  turnarounds = turnarounds.map(t=>parseTurnaround(t,standId));
  turnarounds = orderBy(turnarounds,["start"],["desc"]);
  if(!turnaroundsStartTimestamp)
    return turnarounds;

  let ts = typeof turnaroundsStartTimestamp === 'object' ? turnaroundsStartTimestamp[standId] : turnaroundsStartTimestamp;
  ts = ts || 0;
  turnarounds = turnarounds.filter(i => i.start >= ts);
  return turnarounds;
}

export function parseTurnaround(data:any,standId: string):Turnaround {
  const videos: Record<string,Video> = {};
  if (data.replays) {
    Object.entries(data.replays).forEach(([k,v]: any) => {
      const data: Video = camelCaseKeys(v);
      data.startTs *= 1000;
      videos[k] = data;
    })
  }

  const audios: Record<string,Audio> = {};
  if (data.audios) {
    Object.entries(data.audios).forEach(([k,v]: any) => {
      const data: Audio = camelCaseKeys(v);
      data.startTs *= 1000;
      audios[k] = data;
    })
  }

  const res:Turnaround = {
    id: data.id,
    start: toMilliSecondsOrNull(data.start_ts) as number,
    end: toMilliSecondsOrNull(data.end_ts),
    pushbackMaxSpeed: data.pushback_speed_max,
    videos,
    audios,
    authorized: data.authorized !== false,
    inboundFlight: data.inbound_flight ? camelCaseKeys(data.inbound_flight) : null,
    outboundFlight: data.outbound_flight ? camelCaseKeys(data.outbound_flight) : null,
    pobt: toMilliSecondsOrNull(data.pobt),
    progress:  data.progress || null,
    standId,
    originalStandId: data.original_stand_id
  };
  if(res.end){
    res.pobt = res.end;
    res.progress = 1;
  } else if(res.pobt && res.pobt < now()) {
    res.pobt = now();
  }

  //tmp hack for empty flight info
  if(res.inboundFlight && !Object.keys(res.inboundFlight).length)
    res.inboundFlight = null;
  if(res.outboundFlight && !Object.keys(res.outboundFlight).length)
    res.outboundFlight = null;

  if(res.inboundFlight){
    res.inboundFlight.scheduledInBlockTime = res.inboundFlight.scheduledDateTime;
    res.inboundFlight = convertObjUtcToMillseconds(res.inboundFlight);
  }
  if(res.outboundFlight) {
    res.outboundFlight.scheduledOffBlockTime = res.outboundFlight.scheduledDateTime;
    res.outboundFlight = convertObjUtcToMillseconds(res.outboundFlight);
  }

  return res;
}

export function parseDetections(detections: Detection[]) {
  detections = detections.map(parseDetection);
  if(window.hiddenOperations)
    detections = detections.filter((d:Detection)=>!window.hiddenOperations.includes(d.type));
  if(minOperationConfidence)
    detections = detections.filter((d:Detection)=>
      ignoreMinOperationConfidenceOps.includes(d.type) || (d.confidence && d.confidence >= minOperationConfidence));
  if(mergeOperationsFlag)
    detections = mergeOperations(detections);

  return detections;
}

export function parseDetection(data:any):Detection {
  let group = window.groupsMeta.find(g=>g.operations.includes(data.op_name));
  let detection:Detection = {
    id: data.id,
    confidence: data.confidence,
    startConfidence: data.start_confidence,
    endConfidence: data.end_confidence,
    startDetectionGap: toMilliSecondsOrNull(data.start_detection_gap),
    endDetectionGap: toMilliSecondsOrNull(data.end_detection_gap),
    start: toMilliSecondsOrNull(data.start_ts) as number,
    startState: data.start_state,
    end: toMilliSecondsOrNull(data.end_ts),
    startType: data.start_type,
    endType: data.end_type,
    startLabel: window.eventsLabels[data.start_type],
    endLabel: window.eventsLabels[data.end_type],
    endState: data.end_state,
    type: data.op_name,
    label: window.operationsLabels[data.op_name],
    bboxes: parseBBoxes(data.bboxes),
    group: group ? group.key : OTHERS_GROUP,
    turnId: data.turn_id,
  };
  if(detection.endState === 'RETRACTED'){
    detection.end = null;
  }

  return detection;
}

export function parseCameraOutage(data:any) :CameraOutage {
  return {
    id: data.id,
    start: toMilliSecondsOrNull(data.start_ts) as number,
    end: toMilliSecondsOrNull(data.end_ts),
    camera: data.camera_id
  }
}

function parseBBoxes(data:any): BBoxList {
  const result: BBoxList = {};

  if (!data)
    return result;

  Object.entries(data).forEach(([k,v]: any) => {
    result[k] = {
      box: v.box,
      camera: v.camera_id,
      hires: v.hires,  
    }
  })

  return result;
}

export function mergeOperations(detections: Detection[]) {
  let mergeableKeys = flatten(Object.values(window.mergeableOperations));
  let newOperations = [...detections.filter(o => !mergeableKeys.includes(o.type))];

  for (let key in window.mergeableOperations) {
    let detectionsToMerge: Detection[] = detections.filter(d => window.mergeableOperations[key].includes(d.type));
    if (!detectionsToMerge.length)
      continue;

    detectionsToMerge = sortBy(detectionsToMerge, d => d.start);

    let detection;
    while (detection = detectionsToMerge.shift()) {
      let range = [detection.start, detection.end || now()];
      let newDetection = {...detection};

      for (let detection2 of [...detectionsToMerge]) {
        let range2 = [detection2.start, detection2.end || now()];
        if (!(range[0] <= range2[1] && range[1] >= range2[0]))
          break;

        if (range2[1] > range[1]) {
          newDetection.id = `${detection.id}-${detection2.id}`;
          newDetection.endConfidence = detection2.endConfidence;
          newDetection.endDetectionGap = detection2.endDetectionGap;
          newDetection.end = detection2.end;
          newDetection.endType = detection2.endType;
          newDetection.endLabel = detection2.endLabel;
        }
        detectionsToMerge = detectionsToMerge.filter(o => o.id !== detection2.id);
      }
      newOperations.push(newDetection);
    }
  }
  return newOperations;
}

export function validateAndFix(obj:any, fields:[string,any,boolean,(val:any)=>any][]) {
  fields.forEach(([key, schema, required, parse]) => {
    let value = obj[key];
    if (parse)
      value = parse(value);
    if (required && value == null) {
      let error = new Error(`Missing "${key}".`);
      console.error(error);
      reportError(error);
      throw error;
    }
    if (!required && value == null)
      return;

    let valid = schema.isValidSync(value);
    if (valid)
      return;
    let error = new Error(`Invalid value. ${key} = ${obj[key]}`);
    console.error(error);
    reportError(error);
    obj[key] = null;
    if (required) {
      throw error;
    }
  });

  return obj;
}