import React, { Component, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import styles from "./style.module.scss";
import { StandContext } from "../../services/react";
import StandStore from "../../stores/stand";
import WaveSurfer from "wavesurfer.js";
import classNames from "classnames";
import TimelineStore from "../../stores/timeline";
import TimeAxis from "../Timeline/TimeAxis";
import { clamp, throttle } from "lodash";
import DetectionBar from "../Timeline/DetectionBar";

const AUDIO_DURATION_SECONDS = 32 * 60 + 24;

type Props = {
  className: string;
  timelineStore: TimelineStore;
  onMouseMove: (ev: React.MouseEvent) => void;
  onMouseLeave: (ev: React.MouseEvent) => void;
};

type State = {
  ready: boolean;
  isPlaying: boolean;
  dataUrl: string;
};

export class SoundBar extends Component<Props, State> {
  static contextType = StandContext;
  context!: StandStore;

  ref = React.createRef<HTMLDivElement>();

  audioContext = new AudioContext();
  wavesurfer!: ReturnType<typeof WaveSurfer.create>;

  state: Readonly<State> = {
    ready: false,
    isPlaying: true,
    dataUrl: "",
  };

  get turn() {
    return this.context.selectedTurnaround;
  }

  get src() {
    return Object.values(this.turn?.audios || {})[0]?.url;
  }

  get startTs() {
    return Object.values(this.turn?.audios || {})[0]?.startTs || 0;
  }

  get apuDetections() {
    return this.props.timelineStore.detections.filter(
      ({ type }) => type === "apu"
    );
  }

  componentDidMount() {
    if (this.ref.current) {
      const imgToken = this.context.root.imgToken;
      const url = this.src + `?token=${imgToken}`;

      this.wavesurfer = WaveSurfer.create({
        container: this.ref.current,
        hideScrollbar: true,
        // responsive: true,
        interact: true,
        waveColor: "rgba(255, 255, 255, 0.0)",
        progressColor: "rgba(255, 255, 255, 0.0)",
        normalize: true,
        cursorColor: "#DA584F7F",
        cursorWidth: 3,
        barWidth: 2,
        // barHeight: 1, // the height of the wave
        barGap: 1, // the optional spacing between bars of the wave, if not provided will be calculated in legacy format
      });
      this.wavesurfer.load(url);

      this.wavesurfer.on("ready", async () => {
        this.setState({ready: true});
        // this.wavesurfer.seekTo(1);
        // setTimeout(async () => {
        //   const result = await this.wavesurfer.exportImage('image/png', 1, 'dataURL');
        //   console.log("this.wavesurfer.exportImage", result )
        //     this.setState({dataUrl: result as any})
        //   }, 1800);

      })

      this.updateRegions();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    this.redrawWaveform();
  }

  redrawWaveform = throttle(() => {
    if(this.wavesurfer) {
      this.wavesurfer.drawer.fireEvent('redraw');
    }
  }, 50);

  componentWillUnmount() {
    this.wavesurfer && this.wavesurfer.destroy();
  }


  updateRegions() {
    const progress =  this.turn?.progress || 1;

    // const currentTime = this.wavesurfer.getCurrentTime();
    // const calculatedProgress = currentTime / AUDIO_DURATION_SECONDS;
    // if (calculatedProgress > progress) {
    //   this.onPlayToggle();
    // }
    // console.log("calculatedProgress", calculatedProgress)

    // const { timelineStore } = this.props;

    // const start = this.startTs;
    // const duration = AUDIO_DURATION_SECONDS * 1000;
    // const end = Math.min(start + duration, Date.now());

    // const { isLive } = this.context;
    // const value = isLive ? clamp((end - start) / duration, 0, 1) : 1;

    // const left = timelineStore.getLeftFromTimestamp(start);
    // const normalizedValue = timelineStore.getLeftFromTimestamp(start + (end - start));
    // const result = normalizedValue;
    // console.log("normalizedValue", result)

    // this.wavesurfer.seekTo(clamp(progress, 0, 1));
    // this.wavesurfer.
  }

  onPlayToggle() {
    console.log("playPause  ")
    this.wavesurfer.playPause();
    this.setState({isPlaying: !this.state.isPlaying});
  }

  render() {
    const {ready, dataUrl} = this.state;
    const { timelineStore } = this.props;
    const {isLive,displayedTimestamp} = this.context;
    // const currentTimestamp = isLive ? end : displayedTimestamp;


    const start = this.startTs;
    const duration = AUDIO_DURATION_SECONDS * 1000;
    const end = start + duration;

    const left = timelineStore.getLeftFromTimestamp(start) * 100 + "%";
    const right = timelineStore.getLeftFromTimestamp(Math.min(Date.now(), start + displayedTimestamp)) * 100 + "%";

    const width =
      (timelineStore.getLeftFromTimestamp(end) -
        timelineStore.getLeftFromTimestamp(start)) *
        100 +
      "%";


    return (
      <section
        className={classNames(styles.soundBar, this.props.className)}
        onMouseMove={(ev) => this.props.onMouseMove(ev)}
        onMouseLeave={(ev) => this.props.onMouseLeave(ev)}
      >
        <TimeAxis />
        <div className={classNames(styles.bk)}/>
        <div
          style={{ marginLeft: left, width }}
          ref={this.ref}
          className={classNames(styles.soundBarWrapper)}
        >
          <img src="/waveform.png" className={classNames(styles.waveformImg)} />
        </div>
        {isLive && (
          <div style={{ marginLeft: right }} className={classNames(styles.bk2)}/>
        )}
        {/* {
          dataUrl && (
            <img src={dataUrl} />
          )
        } */}

        <div
          className={classNames(styles.player, { [styles.disabled]: !ready })}
          onClick={() => this.onPlayToggle()}
          onMouseEnter={(ev) => ev.stopPropagation()}
          onMouseMove={(ev) => ev.stopPropagation()}
        >
          <i className={`far fa-${this.state.isPlaying ? "play" : "pause"}`}/>
        </div>

        {this.apuDetections.map((d) => (
          <DetectionBar
            className={styles.apuDetection}
            detection={d}
            onMouseEnter={() => {}}
            onMouseLeave={() => {}}
          />
        ))}
      </section>
    );
  }
}

export default observer(SoundBar);
