import React, {MouseEventHandler, useContext, useRef} from "react";
import classnames from "classnames";
import {observer} from "mobx-react";

import {now} from "../../services/time";
import {TimelineContext} from "./index";
import Detection from "../../models/detection";
import { DetectionEvent } from "../../stores/timeline";
import classNames from "classnames";

type Props = {
  detection: Detection | DetectionEvent,
  onMouseEnter: MouseEventHandler,
  onMouseLeave: MouseEventHandler,
  important?: boolean,
  className?: string,
}

const DetectionBar: React.FC<Props> = ({detection,onMouseEnter,onMouseLeave,important,className}) => {
  const {
    standStore: {displayedTimestamp,inferenceTimestamp,isLive,showConfidenceOnTimeline,openModal},
    getLeftFromTimestamp,
    MAX_DETECTION_LAG
  } = useContext(TimelineContext);

  const ref = useRef<HTMLDivElement>(null);
  let {start,confidence} = detection;
  const end = detection.end || now();

  const left = getLeftFromTimestamp(start)*100 + '%';
  const width = (getLeftFromTimestamp(end) - getLeftFromTimestamp(start))*100 + '%';
  let fillWidth: string | number = '100%';

  if(displayedTimestamp && !isLive && displayedTimestamp > start && displayedTimestamp < end){
    fillWidth = (displayedTimestamp-start)*100/(end-start) + '%';
  }else if(displayedTimestamp && !isLive && displayedTimestamp<=start){
    fillWidth = 0;
  }else if(isLive && !detection.end && inferenceTimestamp) {
    for (const c of Object.keys(detection.bboxes)) {
      const detectionTs = inferenceTimestamp[c];
      if (detectionTs !== undefined) {
        if(now() - detectionTs > MAX_DETECTION_LAG){
          fillWidth = 100*(detectionTs - detection.start)/(now()-detection.start) + '%'
        }
        break;
      }
    }
  }

  const onClick = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    if(!ref.current)
      return;
    let {left,width} = ref.current.getBoundingClientRect();

    let timestamp = start + (end-start)*(ev.clientX - left)/width;
    openModal(timestamp,"original" in detection ? detection.original : detection)
  };

  return (
    <div
      style={{left,width}}
      className={classnames(className, 'detection-bar',{'low-confidence ': detection.confidence && detection.confidence < 0.6}, {important})}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-id={detection.id}
      ref={ref}
    >
      <div className={classNames('fill', {important})} style={{width: fillWidth}}/>
      {showConfidenceOnTimeline && confidence && <span>{Math.ceil(confidence * 100) + '%'}</span>}
    </div>
  )
};

export default observer(DetectionBar);