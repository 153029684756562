import React, { useContext } from "react";
import styles from "./style.module.scss";
import { observer } from "mobx-react";
import classNames from "classnames";
import { TimelineContext } from "../Timeline";

interface Props {
  className?: string;
}

const ApuGroupCol: React.FC<Props> = ({ className }) => {
  const timelineStore = useContext(TimelineContext);
  const apuDetections = timelineStore.detections.filter(
    ({ type }) => type === "apu"
  );

  const activeApuDetection = apuDetections.find((d) => !d.end);

  const date = new Date(
    (activeApuDetection?.end || Date.now()) - (activeApuDetection?.start || 0)
  );

  return (
    <div className={classNames(className, styles.container)}>
      <div
        className={classNames(styles.header, {
          [styles.active]: activeApuDetection,
        })}
      >
        Sound
        {activeApuDetection && <i className="far fa-exclamation-triangle" />}
      </div>
      <div
        className={classNames(styles.content, {
          [styles.active]: activeApuDetection,
        })}
      >
        {activeApuDetection && (
          <>
            <p>APU running...</p>
            <p
              className={styles.date}
            >{`${date.getMinutes()}m ${date.getSeconds()}s`}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default observer(ApuGroupCol);
